<template>
    <div>
        <img :src="imgUrl" alt="Image" width="100%">
    </div>
</template>

<script>
    export default {
        computed: {
            imgUrl: function() {
                return this.$route.params.url;
            },
        },
        created() {
            window.scrollTo(0,0);
        }
    }
</script>

<style scoped>

</style>